<template>
  <div class="pro_material">
    <el-row :gutter="20" class="num-total">
      <el-col :span="4">
        <h4>{{ enquiryTotal.enquiryNum || 0 }}</h4>
        <!-- 询盘数 -->
        <p>{{ $t('productDetail.NumberOfEnquiries') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>{{ enquiryTotal.enquiryCompleteNum || 0 }}</h4>
        <!-- 已下单 -->
        <p>{{ $t('productDetail.Ordered') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>{{ enquiryTotal.enquiryFailNum || 0 }}</h4>
        <!-- 询盘失败 -->
        <p>{{ $t('productDetail.InquiryFailed') }}</p>
      </el-col>
    </el-row>
    <el-table class="mt15" :data="enquiryData" border style="width: 100%">
      <!-- 序号 -->
      <el-table-column
        type="index"
        width="60"
        align="center"
        :label="$t('productDetail.SerialNumber')"
      ></el-table-column>
      <!-- 询盘编号 -->
      <el-table-column
        prop="enquiryCode"
        align="center"
        :label="$t('productDetail.InquiryNumber')"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.enquiryCode"
            :class="[scope.row.skip === 1 ? 'blue-text' : '']"
            @click="
              goToPage('enquiryCode', scope.row.enquiryId, scope.row.skip)
            "
          >
            {{ scope.row.enquiryCode }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 报价单号 -->
      <el-table-column
        align="center"
        prop="priceSheetCode"
        :label="$t('productDetail.QuotationNumber')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.priceSheetCode">
            {{ scope.row.priceSheetCode }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 客户邮箱 -->
      <el-table-column
        align="center"
        prop="customerEmail"
        :label="$t('productDetail.CustomerMailbox')"
        width="180"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.customerEmail"
            :class="[scope.row.skip === 1 ? 'blue-text' : '']"
            @click="emailClick(scope.row.customerEmail, scope.row.skip)"
          >
            {{ scope.row.customerEmail }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 公司名称 -->
      <el-table-column
        align="center"
        prop="companyName"
        :label="$t('productDetail.CompanyName')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 客户代表 -->
      <el-table-column
        align="center"
        prop="businessName"
        :label="$t('productDetail.CustomerRepresentative')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ getName(scope.row, 'businessName') }}
        </template>
      </el-table-column>
      <!-- 来源 -->
      <el-table-column
        align="center"
        prop="enquirySourceName"
        :label="$t('productDetail.Source')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 数量 -->
      <el-table-column
        align="center"
        prop="amount"
        :label="$t('productDetail.Quantity')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 状态 -->
      <el-table-column
        align="center"
        prop="enquiryStatus"
        :label="$t('productDetail.Status')"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <!-- 失败 -->
          <el-tag v-if="row.enquiryStatus == 1" type="info">
            {{ $t('other.M2009') }}
          </el-tag>
          <!-- 作废 -->
          <el-tag v-else-if="row.enquiryStatus == 2" type="info">
            {{ $t('productDetail.Invalid') }}
          </el-tag>
          <el-tag v-else-if="row.enquiryStatus == 3" type="info">
            <!-- 手动完成 -->
            {{ $t('productDetail.DoneManually') }}
          </el-tag>
          <el-tag v-else-if="row.enquiryStatus == 4" type="warning">
            <!-- 待报价 -->
            {{ $t('productDetail.PendingQuotation') }}
          </el-tag>
          <el-tag v-else-if="row.enquiryStatus == 5" type="primary">
            <!-- 已报价 -->
            {{ $t('productDetail.Quoted') }}
          </el-tag>
          <el-tag v-else-if="row.enquiryStatus == 6" type="warning">
            <!-- 已下单 -->
            {{ $t('productDetail.Ordered') }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- 完成日期 -->
      <el-table-column
        align="center"
        prop="updateTime"
        :label="$t('productDetail.CompletionDate')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('productDetail.CreationTime')"
        width="180"
      ></el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="queryForm.pageLe"
      :page.sync="queryForm.pageNo"
      :total="total"
      @pagination="proSelectEnquiryProductPageVO"
    />

    <!-- 报价单预览 -->
    <el-dialog :visible.sync="offerSheetVisible" width="1210px">
      <div class="offer-container">
        <!-- 查看报价单 -->
        <h4 style="font-weight: normal; font-size: 18px">
          {{ $t('other.M2010') }}
        </h4>
        <offerSheet
          v-if="offerSheetVisible"
          :offerSheetInfo="offerSheetInfo"
        ></offerSheet>
      </div>
      <div class="text-center mt20">
        <!-- 关闭 -->
        <el-button @click="offerSheetVisible = false">
          {{ $t('productDetail.Close') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Index'
import {
  getEnquiryProductVO,
  selectEnquiryProductPageVO,
} from '@/api/product/product-info'
import { orderEnquiryUpdate, orderEnquiryDetail } from '@/api/order'
import offerSheet from '@/views/order/inquiryManage/inquiryPrint.vue'
import nameMixin from '@/utils/name-mixin'
export default {
  name: 'EnquiryRecord',
  components: { Pagination, offerSheet },
  mixins: [nameMixin],
  data() {
    return {
      queryForm: {
        pageLe: 10,
        pageNo: 1, //当前页数
      },
      total: 0,
      productId: '', //产品id
      enquiryTotal: {}, //询盘的一些总计
      enquiryData: [], //询盘的记录列表
      offerSheetVisible: false, //报价单查看
      offerSheetInfo: { enquiryIds: '', isBatch: true },
      enquiryForm: {}, //询盘详情
      enquiryPro: 'pro',
    }
  },

  created() {
    if (this.$route.query.proId) {
      this.productId = this.$route.query.proId
      //产品询盘记录与询盘记录列表
      this.proGetEnquiryProductVO()
      this.proSelectEnquiryProductPageVO()
    }
  },
  mounted() {},
  methods: {
    //询盘记录
    async proGetEnquiryProductVO() {
      let response = await getEnquiryProductVO({
        productId: this.productId,
      })
      if ((response.code = '000000')) {
        this.enquiryTotal = response.data
      }
    },
    //询盘记录列表
    async proSelectEnquiryProductPageVO(pageData) {
      if(pageData) {
        this.queryForm.pageNo = pageData.page
        this.queryForm.pageLe = pageData.limit
      }
      let response = await selectEnquiryProductPageVO({
        ...this.queryForm,
        productId: this.productId,
      })
      if (response?.code == '000000') {
        this.enquiryData = response?.data?.data
        this.total = response?.data?.total
      }
    },

    // 询盘单编辑初始化数据
    getEdit(id) {
      orderEnquiryDetail({ businessId: id }).then((res) => {
        const data = res.data
        this.enquiryForm.exportsRise = data.exportsRise
        this.enquiryForm.exportsRiseId = data.exportsRiseId
        this.enquiryForm.enquirySourceName = data.enquirySourceName
        this.enquiryForm.enquirySourceId = data.enquirySourceId
        this.enquiryForm.businessId = data.businessId
        this.enquiryForm.businessName = data.businessName
        this.enquiryForm.fromBusinessId = data.fromBusinessId
        this.enquiryForm.fromBusinessName = data.fromBusinessName
        this.enquiryForm.customerOrderCode = data.customerOrderCode
        this.enquiryForm.customerName = data.customerName
        this.enquiryForm.customerEmail = data.customerEmail
        this.enquiryForm.companyName = data.companyName
        this.enquiryForm.customerPhone = data.customerPhone
        this.enquiryForm.customerRemark = data.customerRemark
        this.enquiryForm.demand = data.demand
        this.enquiryForm.products = data.products
        this.enquiryForm.infoId = data.infoId
        this.offerSheet()
      })
    },
    //报价单信息
    offerSheet() {
      let params = {
        buttonType: 1,
        ...this.enquiryForm,
      }
      orderEnquiryUpdate(params).then((res) => {
        if (res && res.code == '000000') {
          this.offerSheetInfo.enquiryIds = res.data.enquiryId || this.enquiryId
          this.offerSheetVisible = true
        }
      })
    },
    //跳转
    goToPage(str, code, skip) {
      let routeData = null
      if (str === 'enquiryCode') {
        //询盘单跳转
        routeData = this.$router.resolve({
          path: '/order/inquiryManage/inquiryDetail',
          query: {
            enquiryId: code,
            noReturn: true,
          },
        })
      } else if (str === 'priceSheetCode' && skip === 1) {
        return this.getEdit(code)
      }
      if (skip === 1 && routeData) window.open(routeData.href, '_blank')
    },
    //邮件跳转
    emailClick(email, skip) {
      if (skip === 1) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    padding-left: 30px !important;
    border-radius: 2px;
    h4 {
      font-size: 30px;
    }
    p {
      margin-top: 5px;
    }
  }
  > .el-col:nth-child(2) {
    h4 {
      color: green;
    }
  }
  > .el-col:last-child {
    h4 {
      color: red;
    }
  }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
</style>
